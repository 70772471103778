/*------------------------------------*\
# COLOR
\*------------------------------------*/

// Primary, secondary etc...
@color-primary         : #373c82; //dark-purple-blue
@color-primary--light  : #455ac2; //purple-blue
@color-core            : #1A837A; //green
@color-core--light     : #26a69b;
@color-sivilsamfunn                   : #a6d7f0; //light-blue
@color-sivilsamfunn--dark             : #435C74; //blue
@color-sivilsamfunn-header-footer-text: #3D3C3C; //dark-grey
@color-sivilsamfunn-footer-bottom     : #92c9e5; //darker light-blue
@color-valg            : #C45100; //green
@color-valg--light     : #CF7333;
@color-valg--dark      : #893700;

// Neutral colors
@color-neutral--dark    : #939598;
@color-neutral          : #444444;
@color-neutral--light   : #f4f4f4;
@color-neutral--air     : #eaeaea;
@color-border           : #cccccc;
@color-neutral-text     : #595959;

// Suplementary colors
@color-light            : #ffffff;
@color-dark             : #222222;

@color-link             : #5252C8;
@color-link-hover       : #2771bb;
@color-link-core        : #07726a;
@color-link-sivilsamfunn: #435C74; //blue
@color-link-valg        : #1963D3;

@color-lines            : #eaeaea;
@color-bulletpoints     : #7e7e7e;

@color-valg--dark       : #964003;

/*----------------------------------*\
# FONTS
\*----------------------------------*/

@sans-serif: 'Roboto', sans-serif;
@serif: 'Roboto Slab', serif;
