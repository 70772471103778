/* CSS for samfunnsforskning */
@import "../../../../../../lib/uio1/profile/css/util.less";
@import "utils.less";

#hidnav a[href="#bottomnav"] {
  display: none;
}

body {
  font-family: @sans-serif;
  .font-size(18);
  font-weight: 300;
  color: @color-dark;
}

h1,
h2,
h3,
h4,
h5,
h5,
h6 {
  font-family: @serif;
  font-weight: 300;
}

h1 {
  .font-size(36);
}

#vrtx-change-language-link {
  position: relative;
}

#vrtx-blog-listing #vrtx-additional-content > h3:first-child,
.display-as-h2,
.subfolder-menu-alt .menu-title,
.ui-accordion h2.ui-accordion-header,
.vrtx-feedback-title,
.vrtx-image-listing-include-title,
.vrtx-tag-cloud-title,
.vrtx-toc-header,
a.comments-title,
a.feed-title,
h2,
p.topic-title {
  .font-size(26);
}

.display-as-h3,
.ui-accordion h3.ui-accordion-header,
.vrtx-messages .vrtx-resource .vrtx-title a.vrtx-title,
.vrtx-subfolder-menu .menu-title,
.vrtx-toc-style-like-h3 .vrtx-toc-header,
h3 {
  .font-size(22);
}

.vrtx-subfolder-menu .menu-title {
  margin-bottom: 10px;
}

h4 {
  .font-size(20);
  font-weight: 400;
}

h5 {
  .font-size(18);
  font-weight: 400;
  font-style: normal;
}

h6 {
  .font-size(18);
  text-transform: uppercase;
}

.published-date {
  font-weight: 300;
  font-family: @sans-serif;
}

:not(.vrtx-edit-box):not(.consent):not(.consent-settings):not(.vrtx-edit-row):not([class^="admin-frontpage"]):not([id^="search-string-responsive"]):focus,
:focus span {
  background-color: @color-neutral;
  -webkit-box-shadow: 0 0 0 2px @color-neutral;
  -moz-box-shadow: 0 0 0 2px @color-neutral;
  box-shadow: 0 0 0 2px @color-neutral;
  color: @color-light;
}

body .consent .consent-inner,
body .consent-settings .consent-settings-inner {
  button {
    margin-left: 0 !important;
  }
}

body .consent-settings .consent-settings-inner {
  table {
    td, th {
      font-size: 16px;
      line-height: 21px;
    }
  }
  .ui-accordion.ui-widget .ui-accordion-content {
    padding-left: 0;
  }
}

#main .vrtx-social-list {
  a.twitter {
    background: url(../uio1/images/social-list/black-svg/twitter-x.svg) no-repeat center center;
    background-size: 37px !important;
  }
  a.facebook {
    background: url(../uio1/images/social-list/black-svg/facebook.svg) no-repeat center center;
    background-size: 37px !important;
  }
  a.youtube {
    background: url(../uio1/images/social-list/black-svg/youtube.svg) no-repeat center center;
    background-size: 37px !important;
  }
  a.linkedin {
    background: url(../uio1/images/social-list/black-svg/linkedin.svg) no-repeat center center;
    background-size: 37px !important;
  }
  a:hover, a:focus {
    opacity: 0.6;
  }
}

.vrtx-social-components {
  .vrtx-email-friend.vrtx-email-friend, .vrtx-share-at-component li {
    &:before {
      content: "";
      background-size: 34px !important;
      width: 34px;
      height: 34px;
      display: inline-block;
      background-size: 100% auto;
      position: absolute;
      margin-left: 0px;
      margin-top: 0;
    }
    &:hover:before,
    &:focus:before {
      opacity: 0.6;
    }
    &.vrtx-share-at-Twitter {
      background: none;
      &:before {
        background: url(../uio1/images/social-list/black-svg/twitter-x.svg) no-repeat left center;
      }
    }
    &.vrtx-share-at-Facebook {
      background: none;
      &:before {
        background: url(../uio1/images/social-list/black-svg/facebook.svg) no-repeat left center;
      }
    }
  }
  .vrtx-email-friend.vrtx-email-friend {
    background: none;
    &:before {
      background: url(/vrtx/dist/resources/images/mail.svg) no-repeat center left;
      margin-left: -45px;
      margin-top: -3px;
    }
  }
}
.vrtx-social-components .vrtx-share-at-component li.vrtx-share-at-Facebook-true {
  display: inline-block;
}

.vrtx-social-components .vrtx-share-at-component li.vrtx-share-at-FacebookAPI-true {
  display: none;
}

.vrtx-social-components a:focus {
  background-color: transparent;
}
/*#vrtx-structured-article {
  h1, h2, h3, h4, h5, h5, h6 {
    font-weight: 400;
  }
}*/
a {
  color: @color-link;
}
.backgroundOnFocusHoverUnderlinedText(@color-link);
.backgroundOnFocusHoverFooterUnderlinedText(#000);

#comments-header-left a,
#main *:not(.vrtx-messages) > .vrtx-resource a.vrtx-title,
.add-comment-header,
.vrtx-search-results-container div.vrtx-search-results .result h2 a {
  color: @color-link;
  font-weight: 300;
  font-family: @serif;

  &:focus,
  &:hover {
    color: @color-link;
  }
}

.grid-container .vrtx-frontpage-box h2 a {
  color: @color-link;
  text-decoration: none;

  &:focus {
    color: @color-light !important;
  }
}

p {
  margin-bottom: 15px;
}

body .vrtx-facts-container {
  width: 100%;
  &.vrtx-container-right, &.vrtx-container-left {
    width: 40%
  }
}

.grid-container ul > li,
.uio-main ul > li,
ul.vrtx-alphabetical-project-listing > li ul li {
  line-height: 1.5;

  &::before {
    content: "\25CF";
    color: @color-bulletpoints;
    top: 2px;
  }

  h3,
  h4 {
    position: relative;
    top: -12px;
  }
}

ul.vrtx-alphabetical-project-listing,
ul.vrtx-alphabetical-research-group-listing {
  > li {
    .font-size(32);
    font-family: @serif;

    li {
      font-family: @sans-serif;
    }
  }
}

#main .vrtx-back a {
  &:before {
    background-image: url("/vrtx/decorating/resources/dist/src/profile/images/icon-chevron-left.svg");
  }
}
// New navigational feature (hide left menu content but keep space)

#left-main.hidden-content {
  > * {
    display: none;
  }
}

input[type="email"],
input[type="text"],
input[type="search"] {
  &:focus {
    background-color: @color-light;
    color: @color-neutral;
  }
}

.not-for-ansatte {
  .header-search-expand,
  button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search):not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel),
  button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red) input[type="button"]:not(.red),
  button:not([class^="admin-frontpage"]):not([class^="change-positions-in-row"]),
  input[type="cancel"]:not(.red),
  input[type="submit"]:not(.submit-comment-button):not(.red) {
    background-color: @color-primary !important;
    padding: 12px 20px 12px 20px !important;
    .font-size(18);
    font-weight: 300;
    margin-left: 15px;
    height: 3em;

    &:focus,
    &:hover {
      text-decoration: underline !important;
      background-color: @color-primary !important;
    }
    &:after, &:before {
      display: none
    }
  }

  #main form.vrtx-search-form input[type=search] {
    height: 3em;
    font-size: 1.8rem;
    border-right: 4.2em solid #fff;
  }

  &.header-search-collapsable {
    .header-search-expand {
      background: #737677 url("../images/magnifier.svg") no-repeat 0 17px;
      background-size: 15px auto;
      padding-left: 25px !important;
      .font-size(18);
      font-weight: 300;
      text-decoration: none;
    }

    &:not(.header-search-collapsed) {
      .head-menu,
      .header-search-expand {
        float: right;
      }
    }
  }

  .menu-search {
    overflow: hidden;
    height: 0;
    display: flex;
    align-items: center;

    input[type="text"] {
      border-color: @color-lines;
      .borderRadius(4px);
    }

    #search-string-responsive {
      width: 517px;
      height: 52px;
      border: 1px solid @color-border;

      &:focus {
        border: 1px solid @color-neutral;
      }
    }

    > form {
      margin: 0 auto;
      width: 970px;
      text-align: center;
    }

    button {
      height: 50px;
    }
  }

  .header-search {
    top: 200px;

    form {
      display: none;
    }
  }

  #head-wrapper {
    background: @color-primary;

    #head {
      .uio-app-name {
        display: none;
      }
      height: 194px;

      .head-menu {
        right: 0;

        .link-in-head-menu a {
          span {
            display: none;
          }

          &::after {
            content: "Intranett" !important;
          }
        }

        .link-in-head-menu-eng a {
          text-decoration: none;
          &:focus,
          &:hover {
            text-decoration: underline;
          }
          span {
            display: none;
          }

          &::after {
            content: "Internal pages" !important;
          }
        }

        a {
          color: @color-light;
        }

        .vrtx-dropdown-wrapper ul li a {
          color: @color-link !important;
          text-decoration: none;
          &:focus,
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  #head-wrapper #head #header,
  .vrtx-image-listing-include #header {
    a#logo {
      color: @color-light;
      font-family: @serif;
      .font-size(45);
      font-weight: 300;
      position: absolute;
      top: 40px;
      text-decoration: none;
      img {
        width: 200px;
      }

      &:focus,
      &:hover {
        text-decoration: none;
      }

      &[href$="/english/"] {
        line-height: 1.22 !important;
      }
    }

    .header-samarbeid {
      .font-size(19);
      font-family: @serif;
      font-weight: 300;
      position: absolute;
      top: 118px;

      a {
        &:focus,
        &:hover {
          text-decoration: none;
          border-bottom: none;
        }
      }
    }
  }

  .vrtx-image-listing-include .fullscreen-gallery-topline {
    height: 150px;

    #header {
      a#logo {
        top: 25px;
      }

      .header-samarbeid {
        color: #fff;
        top: 75px;

        a {
          border-bottom: 1px solid #fff;
        }
      }
    }
  }
}

#head-wrapper #globalnav {
  background: @color-primary none repeat scroll 0 0;
  font-family: @sans-serif;

  ul li {
    &.intranett {
      display: none;
    }
    margin-right: 7px;

    &.vrtx-active-item a,
    a {
      padding: 12px 15px 13px;
      font-weight: 300;
      .font-size(18);
      color: @color-light;
      text-decoration: none;

      &:focus,
      &:hover {
        background-color: @color-primary;
        color: @color-light;
        text-decoration: none;
        margin-top: 0;
      }
    }

    &.parent-folder {
      margin-left: -15px;
    }

    &.vrtx-active-item {
      a {
        background-color: @color-primary;
        text-decoration: none;
        border-bottom: 6px solid @color-light;
      }
    }

    a {
      &.parent-folder:focus,
      &.parent-folder:hover,
      &:focus,
      &:hover {
        border-bottom: 6px solid @color-light;
      }
    }
  }
}

#main {
  // Comment out to display regular resource listing
  .tagged-resources.vrtx-resources {
    margin: 0;

    .vrtx-resource {
      .vrtx-title {
        margin-bottom: 0;
        display: inline;

        a {
          font-size: inherit;
          line-height: inherit;
        }
      }

      a.vrtx-image ~ * {
        margin-left: 0;
      }
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 10px;

      .introduction,
      .publish-date,
      .vrtx-image {
        display: none;
      }
      // Display bulletpoints
      display: list-item;
      list-style-type: none;
      position: relative;
      margin: 0 0 5px 2.5ex;

      &:before {
        content: "\25CF";
        color: @color-bulletpoints;
        margin-left: -2.5ex;
        position: absolute;
        top: 2px;
        left: 0;
        width: 1em;
        overflow: hidden;
        font-size: 0.8em;
      }
    }
  }

  ul.three-column-list {
   columns: 3;
  }

  .navigation-links {
    li {
      min-height: 81px !important;

      a {
        background-color: #f5f7f8;
        .borderRadius(4px);
        .font-size(17);

        &::after {
          background: transparent url("../images/arrow-forward-dark-blue.svg") no-repeat scroll 0 0 / auto 100%;
          background-size: 16px 16px;
        }

        &.active,
        &:focus,
        &:hover {
          color: @color-light;
          background-color: @color-primary--light;

          &:after {
            background-image: url("../images/arrow-forward-white.svg");
          }
        }
      }
    }

    &.navigation-links li:nth-child(n) {
      padding-top: 2px;
    }

    &.navigation-links li:nth-child(2n + 1) {
      padding-bottom: 2px;
      padding-left: 2px;
    }

    &.navigation-links li:nth-child(2n + 2) {
      padding-right: 2px;
    }

    &.navigation-links-three-columns {
      li {
        &:nth-child(2n + 2) a::after {
          right: 30px !important;
        }

        &:nth-child(n) {
          padding: 2px;
          width: 33.333%;
        }

        &:nth-child(3n + 1) {
          padding-right: 10px;
          clear: left;
        }

        &:nth-child(3n + 2) {
          padding-right: 10px;
          padding-left: 10px;
          clear: none;
        }

        &:nth-child(3n + 3) {
          padding-left: 10px;
          clear: none;

          a:after {
            right: 15px !important;
          }
        }
      }
    }
  }

  .grey-box {
    .navigation-links li a {
      background-color: @color-light;
      text-decoration: none;

      &:focus,
      &:hover {
        color: @color-light;
        background-color: @color-primary--light;

        &:after {
          background-image: url("../images/arrow-forward-white.svg");
        }
      }
    }
  }

  .button:not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel) {
    background-image: url("../images/arrow-forward-white.svg") !important;
    //background-size: 30px 16px !important;
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: auto 0.8em !important;
    background-color: @color-primary !important;
    color: @color-light !important;
    font-family: @sans-serif;
    .font-size(17);
    font-weight: 300;
    line-height: 1.06;
    padding: 17px 60px 17px 20px !important;
    .borderRadius(4px);
    vertical-align: middle;

    &:focus,
    &:hover {
      background-image: url("../images/arrow-forward-white.svg") !important;
      color: @color-light;
      background-color: @color-primary--light !important;
      text-decoration: underline;
    }
  }

  .row-thirds-double:not(.row-one-colored):not(.row-thirds-double-no-vertical-line) {
    .vrtx-frontpage-box.third-box-right {
      border-left: none;
    }

    .vrtx-frontpage-box.third-box-left {
      border-right: none;
    }
  }

  .vrtx-paging-feed-wrapper {
    border-top: none;
  }
}

.vrtx-frontpage-box {
  height: fit-content !important;
}

#left-main ul.vrtx-uri-menu {
  border-bottom: 1px solid @color-neutral--air !important;

  .contains-subfolders {
    background: url("../images/meny-arrow-down.svg") no-repeat 100% 22px;
    padding-right: 10px !important;
  }

  li {
    border-top: 1px solid @color-neutral--air !important;
    padding: 10px 0;

    a {
      font-weight: 400;
      .font-size(16);
      text-decoration: none;

      &:hover {
        background-color: transparent;
        color: @color-dark;
        text-decoration: underline;
      }
    }

    &.parent-folder {
      display: none;
      // Hide border from left menu if only child
      &:only-child {
        display: block;
        height: 1px;
        border-color: #fff !important;
        overflow: hidden;
        margin: 0;
        padding: 0;
        position: relative;
        top: 1px;
      }
    }

    &.vrtx-current-item > a {
      color: @color-dark;

      &:focus {
        background-color: @color-light;
      }
    }

    ul {
      padding-left: 20px;

      li {
        border-top: none !important;
      }
    }
  }
}

.select-chooser {
  overflow: hidden;
}

table th {
  font-weight: bold;
}

.vrtx-introduction {
  font-family: @serif;
  line-height: 1.7;
  border-bottom: 4px solid @color-lines;
  padding-bottom: 12px;
}

#vrtx-blog-listing .vrtx-introduction {
  border-bottom: none;
}

.vrtx-facts-container {
  width: 45%;
  padding: 25px !important;

  h2 {
    .font-size(21);
    margin-top: 0 !important;
  }
}

blockquote {
  font-family: @serif;
  .font-size(22);
  font-weight: 300;
  text-align: center;
  border-top: 4px solid @color-lines;
  border-bottom: 4px solid @color-lines;
  border-left: none;
  padding: 33px 0;
  margin-bottom: 41px !important;
  margin-top: 54px !important;
}

.grid-container {
  .vrtx-frontpage-box {
    &.img-special-left {
      h2 {
        .font-size(32);
        font-weight: 300;
        line-height: 1.38;
      }

      .vrtx-box-content,
      .vrtx-box-content p {
        .font-size(18);
        font-weight: 300;
        line-height: 1.6;
      }

      &.img-special-left {
        .vrtx-frontpage-box-picture {
          margin-left: -95px;
          max-width: 62%;
        }
      }
    }
  }

  &.grid-color-blue,
  &.grid-color-green,
  &.grid-color-light-blue {
    + .row-all-colored {
      margin-top: -40px;
    }
  }

  &.grid-color-blue {
    background-color: @color-primary !important;

    a,
    h2,
    p {
      color: @color-light !important;
    }
  }

  &.grid-color-light-blue {
    background-color: @color-sivilsamfunn !important;

    a,
    h2,
    p {
      color: @color-dark !important;
    }
  }

  &.grid-color-green {
    background-color: @color-core !important;

    a,
    h2,
    p {
      color: @color-light !important;
    }
  }
}

#main .vrtx-frontpage-box {
  &.no-margin-top {
    h2 {
      margin-top: 0;
    }

    img {
      margin-top: -15px !important;
    }
  }
}

#vrtx-frontpage {
  .vrtx-frontpage-box.hide-first .vrtx-feed .item-1 {
    display: none;
  }

  .vrtx-frontpage-box.hide-second .vrtx-feed .item-2 {
    display: none;
  }

  .vrtx-frontpage-box.hide-third .vrtx-feed .item-3 {
    display: none;
  }

  .vrtx-frontpage-box.hide-fourth .vrtx-feed .item-4 {
    display: none;
  }

  .vrtx-frontpage-box.hide-fifth .vrtx-feed .item-5 {
    display: none;
  }

  .vrtx-feed {
    a.item-title {
      .font-size(21);
      font-family: @serif;
      line-height: 1.33;
      font-weight: 400;
    }

    span.published-date {
      font-family: @sans-serif;
      .font-size(16);
      margin-top: -10px;
      display: block;

      &::before {
        content: " ";
        width: 58px;
        display: block;
        border-bottom: 3px solid @color-lines;
        margin: 25px 0 15px;
      }
    }

    .item-description p {
      .font-size(16);
      line-height: 1.67;

      &:first-child {
        margin-top: 20px;
      }
    }
  }
}

#vrtx-daily-events,
.vrtx-event-component {
  .vrtx-daily-events-listing,
  .vrtx-event-component-item {
    .vrtx-date-icon,
    .vrtx-daily-events-date {
      margin-top: 10px !important;

      .vrtx-date-icon-day,
      .vrtx-daily-events-date-day {
        font-family: @serif;
        font-weight: bold;
        .font-size(36);
      }
      .vrtx-date-icon-month,
      .vrtx-daily-events-date-month {
        color: @color-neutral;
        .font-size(16);
      }
    }

    .vrtx-event-component-title {
      padding-bottom: 10px;
    }

    .vrtx-event-component-misc {
      font-family: @sans-serif;
      .font-size(16);

      &:before {
        padding-bottom: 12px;
      }
    }
  }
}

.vrtx-event-component-title.summary {
  font-family: @serif;
  .font-size(21);
  line-height: 1.33;
  font-weight: 400;
}

#vrtx-additional-content .vrtx-frontpage-box {
  border-bottom: 4px solid #eaeaea;
}

.vrtx-authors,
.vrtx-date-info {
  border-top: 4px solid @color-lines;
  .font-size(16);
  color: @color-dark;
  margin-top: 32px;
}

.published-date {
  color: @color-dark;
}
// Fjerner uio-søk fra søketreffsiden

#vrtx-searchview #main .vrtx-search-results .vrtx-topuioresult {
  display: none;
}
// Blog

body.blog #main .grid-container.full-width-picture-bg .vrtx-box-content {
  width: 100%;

  a {
    font-family: @serif;
  }
}

body.blog #main .grid-container.blog-recent-posts .vrtx-feed a.item-title {
  font-family: @serif;
  font-weight: 400;
}

body.blog#vrtx-blog-listing .vrtx-resources .vrtx-resource .vrtx-title ~ * {
  .font-size(20);
  .line-height(32);
}

body.blog #main #vrtx-main-content .vrtx-introduction,
body.blog #main h1 + .author-published-byline {
  .font-size(20);
  .line-height(32);
}

body.blog #main .author-published-byline {
  font-family: inherit;
}
/* Project */
table.vrtx-participants-table {
  border: none;

  th {
    border-width: 0 0 3px 0;
    font-weight: 300;
    border-right: 0;
  }

  td {
    border-bottom: 1px solid @color-lines;
    border-right: 0;

    .vrtx-mobile,
    .vrtx-phone,
    .vrtx-position {
      display: block;
    }
  }
}

.vrtx-isf-project-status {
  font-weight: bold;
  padding-bottom: 19px;
}

.vrtx-project-meta {
  margin-bottom: 20px;
  margin-top: -13px;
  position: relative;

  .vrtx-project-meta-line {
    line-height: 2.5;
    position: relative;

    .vrtx-label {
      font-weight: bold;
      position: absolute;
      left: 0;
    }

    .vrtx-value {
      padding-left: 170px;
      display: inline-block;
    }
  }
}

.vrtx-comp-person-list {
  margin-top: 20px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  //number: 2;
  justify-content: space-between;

  .isfProjectParticipant,
  .isfTagParticipant {
    margin-bottom: 20px;
    padding-right: 20px;
    width: 50%;

    img {
      max-width: none !important;
      height: auto !important;
      max-height: 150px;
      float: left;
    }

    .person-contact-info {
      .font-size(16);
      color: @color-neutral-text;
      padding-left: 120px;
      margin-top: -5px;

      > span {
        display: block;
      }
    }
  }

  .more-participants {
    clear: left;
    display: block;
    width: 100%;
  }
}

.facts-box {
  background-color: @color-neutral--light;
  padding: 25px 22px 10px 25px;
}

#vrtx-main-content .facts-box {
  margin-top: 40px;

  h2 {
    margin-top: 0;
  }

  + .vrtx-paging-feed-wrapper {
    margin-top: 10px;
  }
}
/* project ^*/
/* Related projects */
#vrtx-related-projects-completed-toggle {
  &:before {
    background-image: url("../images/icon-plus.svg");
    top: 0.3ex;
  }

  &[aria-expanded="true"]:before {
    background-image: url("../images/icon-minus.svg");
  }
}

.vrtx-comp-hide-completed-project-list,
.vrtx-comp-show-completed-project-list {
  padding-left: 28px;
  position: relative;
  display: inline-block;

  &:before {
    background: url("../images/icon-plus.svg") no-repeat 0 25%;
    background-size: auto 100%;
    width: 1.2em;
    height: 1.2em;
    content: " ";
    position: absolute;
    top: 0.3ex;
    left: 0;
    display: inline-block;
  }
}

.vrtx-comp-hide-completed-project-list {
  &:before {
    background-image: url("../images/icon-minus.svg");
  }
}

.vrtx-comp-completed-project-list.visible {
  display: block !important;
}
/* Person */
#vrtx-person h1 {
  padding-bottom: 37px;
}

#vrtx-isf-person-change-language-link {
  position: absolute;
  top: 0;
  right: 0;
}

.vrtx-person-other-units {
  display: none;
}

#vrtx-person-contact-info-wrapper {
  border-bottom: 4px solid @color-lines;
  padding-bottom: 23px;

  .vrtx-person-image {
    max-width: 220px;
    height: auto;
  }

  .vrtx-person-contactinfo {
    line-height: 2;
    margin-left: 260px;

    .vrtx-person-contact-info-line {
      display: flex;
      flex-direction: column;

      .vrtx-value {
        margin-left: 0;
      }
    }
    // Id #vrtx-person-contact-info-extras in document has an error
    > div:last-child {
      border-top: none;
      padding-bottom: 0;
      line-height: 28px;
      font-size: 17px;
      /*
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      */
      #vrtx-person-full-cv,
      #vrtx-press-photo + #vrtx-person-vcard {
        margin-left: 40px;
      }
    }
  }
}

.en #vrtx-person-contact-info-wrapper .vrtx-person-contactinfo {
  // Id #vrtx-person-contact-info-extras in document has an error
  > div:last-child {
    #vrtx-person-full-cv,
    #vrtx-press-photo + #vrtx-person-vcard {
      margin-left: 35px;
    }
  }
}

#vrtx-person-contact-info-extras,
.vrtx-tags {
  a {
    text-decoration: underline;

    &:focus,
    &:hover {
      background-color: @color-link;
      box-shadow: 0 0 0 2px @color-link;
      color: #fff;
      text-decoration: none;
    }
  }
}

.vrtx-tags {
  .font-size(18);
  border-top: 4px solid @color-lines;
}

#vrtx-publications-wrapper {
  border-top: 4px solid #eaeaea;
  margin-top: 23px;
  padding-top: 0;
}

#vrtx-publication-tabs {
  &:nth-child(n) {
    .font-size(18);
    line-height: 1.67;
  }
  // li.ui-state-default.ui-corner-top[aria-controls*="tab-4"] {
  //   display: none; 
  // }
}

#vrtx-publications-wrapper .ui-tabs.ui-widget.ui-widget-content {
  .ui-tabs-panel {
    border: none;
  }

  .ui-tabs-nav li {
    .font-size(16);
    border: none;
    margin: 1px 0.8em 0 0;
    background: @color-neutral--air;
    .borderRadius(25px);

    &.ui-state-active,
    &.ui-state-hover {
      background: @color-link;

      a {
        color: @color-light !important;

        &:after {
          display: none;
        }
      }
    }

    a {
      background-color: transparent;
      padding: 0.3em 1em;
      display: block;
      color: @color-neutral !important;
    }
  }
}

#vrtx-listing-completed-ongoing,
#vrtx-publications-wrapper .ui-tabs.ui-widget.ui-widget-content p.vrtx-more-external-publications {
  border-top: none;

  a {
    background: url("../images/arrow-forward-dark-blue-bullet.svg") no-repeat 0 0 !important;
    margin-bottom: -5px;
    padding-bottom: 5px;
    padding-left: 38px;
    font-weight: 400;
  }
}

table.vrtx-person-listing,
table.vrtx-unit-listing {
  .font-size(16);
}

#vrtx-person-listing {
  .subfolder-title,
  .vrtx-relationprop-container {
    float: left;
    width: 40% !important;

    ul {
      margin-top: 0;
    }
  }

  .vrtx-tags-container {
    float: left;
    width: 60% !important;

    ul {
      margin-top: 0;
    }
  }
}
/* Person ^ */
/* Publications */
#vrtx-publications-wrapper {
  border-top: 0;
}

#main .vrtx-external-publications li {
  margin: 0 0 10px;
  padding-left: 35px;
  text-indent: -35px;

  &::before {
    content: " ";
  }
}

.vrtx-publication-title-compact {
  font-weight: 400;
  display: block;
  margin: 20px 0 10px;
}

#main span.vrtx-publication-summary {
  display: block;
  text-indent: 0;

  a.vrtx-publication-summary {
    display: inline-block;
    margin-top: 8px;

    &:before {
      content: "";
      width: 1em;
      height: 1em;
      margin: 0 5px -2px 0;
      display: inline-block;
      background: url("/vrtx/decorating/resources/dist/src/profile/images/icon-chevron-right.svg") no-repeat 0 0;
    }

    &:focus,
    &:hover {
      &:before {
        background-image: url("/vrtx/decorating/resources/dist/src/profile/images/icon-chevron-right-white.svg");
      }
    }
  }

  p {
    max-width: 100%;
  }
}
/* Publications ^ */

#main div.vrtx-feed-link {
  display: none; //rt-sak: 5720786
}
// .vrtx-social-components {
//   display: none; //rt-sak: 5720786
// }
#bottomnav {
  display: none !important;
}

.footer > .vrtx-search-scope a,
a.all-comments,
a.all-messages,
a.more-url,
a.vrtx-more,
div.vrtx-more a {
  padding: 2px 0;
  text-decoration: none;

  &:before {
    background: url("../images/arrow-forward-dark-blue-bullet.svg") no-repeat 0 0 !important;
    margin-bottom: -5px;
    padding-bottom: 5px;
  }
}

.ui-accordion.ui-widget {
  .ui-accordion-header {
    .ui-icon {
      background-image: url("../images/icon-chevron-right-purple.svg");
    }
    &:focus .ui-icon {
      background-image: url(../images/icon-chevron-right-white.svg);
    }
  }
  .ui-accordion-header.ui-accordion-header-active {
    .ui-icon {
      background-image: url("../images/icon-chevron-down-purple.svg");
    }
    &:focus .ui-icon {
      background-image: url(../images/icon-chevron-down-white.svg);
    }
  }
}

.js .toggle-more {
  & > {
    h2:before,
    h2:before,
    h3:before,
    h4:before,
    h5:before,
    h6:before {
      background-image: url("../images/icon-chevron-right-purple.svg");
    }

    h2:before {
      top: 0.7ex;
    }

    h3:before {
      top: 0.9ex;
    }

    h4:before {
      top: 1.3ex;
    }

    h5:before {
      top: 1.6ex;
    }

    h6:before {
      top: 0.6ex;
    }
  }

  &.toggle-more-active > {
    h2:before,
    h3:before,
    h4:before,
    h5:before,
    h6:before {
      background-image: url("../images/icon-chevron-down-purple.svg");
    }
  }
}
//newsletter

#mc_embed_signup {
  background: @color-light;
  clear: left;
  font: 14px @sans-serif;

  .button {
    background-size: auto 0.8em !important;
  }
}
// Footer

.not-for-ansatte {
  &:not(.header-context) {
    .responsible-login .content {
      margin-left: 90px;
    }
  }

  #footer-wrapper {
    background-color: @color-primary;
    .font-size(16);
    font-weight: 300;
    line-height: 1.69;
    padding: 40px 0;

    *,
    a {
      color: @color-light;
    }

    .col-2-5,
    .col-3-5 {
      padding-top: 100px;
    }

    .col-3-5 {
      width: 70%;
    }

    .col-2-5 {
      width: 30%;

      .content {
        margin-left: 40px;
      }
    }

    .col-1-2 .content,
    .col-2-5 .content {
      margin-top: 45px;

      a.email {
        word-break: break-all;
      }
    }

    .footer-logo {
      margin-right: 0;
    }

    .footer-logo img {
      position: absolute;
      width: 200px;
      margin-top: -100px;
    }

    .eco-lighthouse {
      a {
        width: 67px;
        padding: 0;
        height: 58px;
        display: block;
      }
    }

    .menu-label {
      .font-size(18);
      font-weight: bold;
      font-family: @sans-serif;
    }

    .green-row {
      display: block;
      bottom: 0;
      height: 116px;
    }
  }
}

.footer-bottom-row {
  p {
    width: 1010px;
    margin: 0 auto;
    padding-top: 12px;
    padding-left: 20px;
    .font-size(16);
    height: 114px;

    span.footer-logo {
      padding-top: 7px;
      display: flex;
      align-items: center;
    }
  }
}
// Big project

.banner-container {
  display: flex;
  line-height: 1.2;

  #banner-container-title {
    font-size: 40px;
    font-family: @serif;
    font-weight: 400;
    top: 0;
    margin-top: auto;
    margin-bottom: 20px;
  }
}

#vrtx-frontpage {
  .banner-container {
    + #main {
      h1 {
        display: none;
      }
    }
  }
}

.vrtx-isf-project-frontpage-info {
  dd,
  dt {
    display: inline;
    margin: 0;
  }

  dt {
    display: inline-block;
    min-width: 150px;
  }

  dd {
    &:after {
      content: "";
      clear: both;
      display: block;
      margin-bottom: 20px;
    }
  }
}
/* Special - local adjustments */
html.special-page-config,
html.special-page-config-header-minimal {
  #main {
    blockquote:before,
    h2 {
      color: #5252c8;
    }

    .vrtx-introduction {
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  blockquote {
    font-family: Georgia, serif;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    border: none;
  }

  body:not(.ritmo) {
    #footer-wrapper {
      background-color: #373c82;

      .vrtx-dropdown-component-toggled .vrtx-login-manage-link {
        background-image: url("../uio1/images/dropdown.png");
      }
    }

    #footer-wrapper,
    #footer-wrapper a,
    #footer-wrapper h2 {
      color: #fff;
    }
  }

  body:not(.ritmo) #vrtx-main-content .special-logo-video-grid-container + .grid-container .vrtx-frontpage-box:first-child .vrtx-box-content > p:first-child:first-letter,
  body:not(.ritmo) #vrtx-main-content > .grid-container:first-child .vrtx-frontpage-box:first-child .vrtx-box-content > p:first-child:first-letter {
    color: #5252c8;
  }
}
@media only screen and (max-width: 16cm) and (orientation: portrait), only screen and (max-width: 19cm) and (orientation: landscape) {
  .banner-container {
    margin-bottom: 120px;
  }
  // fra frontpage.less
  #vrtx-frontpage #main {
    padding-top: 0;
    margin-top: -90px;

    .vrtx-frontpage-box.grey-box:not(.responsive-hide) + .grey-box {
      margin-top: 0;
    }

    .grid-container,
    .vrtx-frontpage-box {
      border-top: none !important;
    }

    .vrtx-frontpage-box.no-margin-top {
      .vrtx-box-content .navigation-links:last-child {
        margin-bottom: 0;

        li:nth-child(n):not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }

    .vrtx-frontpage-box {
      &.img-special-left {
        &.vrtx-frontpage-box-picture {
          padding-bottom: 15px;
        }

        h2 {
          .font-size(25);
          font-weight: 300;
          line-height: 1.48;
        }

        .vrtx-box-content {
          font-weight: 300;
          line-height: 1.63;
        }

        .vrtx-frontpage-box-picture {
          margin-left: 0;
          max-width: 100%;
        }
      }
    }

    .vrtx-frontpage-box.no-margin-top h2 {
      //overstyrer fordi margen som blir for mye på desktop, må være der på responsivt.
      margin-top: 0;
    }

    .vrtx-feed.with-images {
      ul.items li {
        margin-left: 2.5ex;

        a.item-title {
          .font-size(18);
          font-weight: 300;
        }

        .item-description,
        .vrtx-image,
        span.published-date {
          display: none !important;
        }

        &:before {
          display: block;
          margin-left: 0;
        }
      }
    }
  }

  #main {
    margin-top: 0;
  }
  /*
  .js-ready.csstransforms.csstransitions .grid-container:nth-child(2) {
    margin-top: 0;
  }
*/
  .js-ready.csstransforms.csstransitions #vrtx-frontpage .grid-container:nth-child(2) {
    margin-top: 0px;
  }

  p {
    margin-bottom: 5px;
  }

  .not-for-ansatte {
    h1 {
      .font-size(30);
    }

    h2 {
      .font-size(30);
    }

    #head-wrapper {
      background: none @color-primary;
      height: auto;

      #head {
        height: auto;

        #header {
          padding: 15px;

          a#logo {
            font-family: @serif;
            .font-size(26);
            line-height: 0; //1.22;
            font-weight: 300;
            position: static;
            display: inline-block;
          }

          .header-samarbeid {
            display: inline-block;
          }
        }
      }
    }

    .vrtx-image-listing-include .fullscreen-gallery-topline {
      height: auto;

      #header {
        display: none !important;
      }
    }

    #menu-wrapper .menu {
      li {
        background-color: @color-primary;

        &.active {
          background-color: darken(@color-primary, 4%);
        }

        a {
          .font-size(22);
          font-weight: 300;
          padding-left: 8px;

          &.toggle-navigation .icon {
            background: url("../images/nav-icon.svg") no-repeat 14px 1px;
          }

          &.toggle-search .icon {
            background: url("../images/search-icon.svg") no-repeat 6px 0;
            background-size: 20px 20px;
          }

          &:focus,
          &:hover {
            text-decoration: none !important;
          }
        }
      }
    }

    &:not(.no-touch) #menu-wrapper .menu li a {
      &:focus,
      &:hover {
        text-decoration: underline !important;
      }
    }
  }

  .not-for-ansatte .menu-search {
    height: auto !important;
    background: @color-light;
    display: block !important;

    #search-string-responsive {
      padding-right: 0;
      width: 100%;
      border-right: 5.2em solid #000;
    }

    > form {
      width: 100%;
      margin: 0;
    }

    button {
      background-color: @color-primary;
      border-left: 10px solid @color-light !important;

      &:focus,
      &:hover {
        background-color: @color-dark;
      }

      &:after,
      &:before {
        border: 1px solid #ccc !important;
        border-width: 1px 0 !important;
        height: 52px;
      }

      &:after {
        border: 1px solid #fff;
      }
    }
  }

  .increase-h2-margin .vrtx-frontpage-box h2 {
    margin-top: 40px !important;
  }

  #nav-offcanvas {
    .nav-seperator {
      height: auto;
    }
    ul li.parent {
      ul {
        margin-left: 20px;
        margin-bottom: 10px;

        li {
          position: relative;

          &:before {
            content: "\25A0";
            margin-left: 0;
            color: #888;
            //color: #666;
            position: absolute;
            top: 0.6rem;
            left: 0.3rem;
            width: 1rem;
            overflow: hidden;
            font-size: 1rem;
          }
        }
      }

      &:not(.active-item) {

        >a,
        >span.no-url {
          padding-top: 0.7em;
          padding-bottom: 0.7em;
        }
      }
    }
  }

  #main {
    .navigation-links.navigation-links {
      // Extra specificity
      margin: 20px 0 -20px;

      li {
        height: auto !important;
        min-height: 0 !important;

        a {
          height: auto !important;
          min-height: 0 !important;
          background-color: transparent !important;
          padding: 0 40px 0 35px;
          background: url("../images/arrow-forward-dark-blue.svg") no-repeat scroll 3px 4px / auto 100%;
          background-size: 16px 16px !important;

          &::after {
            background: none !important;
          }

          &:focus,
          &:hover {
            background-color: transparent;
            color: @color-link !important;
            text-decoration: underline;
          }
        }
      }
    }

    #vrtx-publications-wrapper .ui-tabs-nav {
      li.toggle-more-options-dropdown a {
        background-position: 100% -2px;
        background-color: @color-neutral--air;
        padding-right: 30px;
      }

      li.ui-tabs-active {
        .borderRadius(0);
      }

      .more-options-dropdown-title {
        background: @color-link;
      }
    }

    .vrtx-search-container form,
    form.vrtx-big-search,
    form.vrtx-big-search#enheter {
      input[type="text"],
      input[type="search"],
      button[type="submit"]:before,
      button[type="submit"]:after {
        height: 52px;
      }
    }

    #vrtx-show-advanced-search {
      display: none !important;
    }
  }

  #vrtx-person-contact-info-wrapper {
    padding-bottom: 20px;

    .vrtx-person-image {
      max-width: 100%;
    }

    .vrtx-person-contactinfo {
      line-height: 2;
      margin-left: 0;

      .vrtx-person-contact-info-line {
        display: block;

        .vrtx-value {
          margin-left: 0;
        }
      }
      // Id in document has an error
      //#vrtx-person-contact-info-extras {}

      > div:last-child {
        position: static;
        border-top: none;
        padding-bottom: 0;

        #vrtx-person-full-cv,
        #vrtx-press-photo + #vrtx-person-vcard {
          margin-left: 0;
          display: block;
        }
      }
    }
  }

  #vrtx-isf-person-change-language-link {
    position: absolute;
    top: 20px;
    right: 15px;
  }

  .vrtx-comp-person-list {
    margin-top: 20px;
    position: relative;
    display: block;

    .isfProjectParticipant,
    .isfTagParticipant {
      margin-bottom: 20px;
      padding-right: 0;
      width: 100%;

      img {
        max-width: 100% !important;
        max-height: none;
        float: none;
        margin-bottom: 10px;
      }

      .person-contact-info {
        padding-left: 0;
        margin-top: 0;

        > span {
          display: block;
        }
      }
    }

    .more-participants {
      clear: left;
      display: block;
      width: 100%;
    }
  }

  #main td.vrtx-person-listing-degree {
    display: none;
  }

  #vrtx-person-listing {
    .subfolder-title,
    .vrtx-relationprop-container,
    .vrtx-tags-container {
      width: auto !important;
      float: none;

      ul {
        margin-bottom: 0;
      }
    }
  }

  #vrtx-daily-events,
  .vrtx-event-component {
    .vrtx-daily-events-listing,
    .vrtx-event-component-item {
      .vrtx-date-icon,
      .vrtx-daily-events-date {
        margin-top: 10px !important;

        .vrtx-date-icon-day,
        .vrtx-daily-events-date-day {
          .font-size(30);
        }
      }
    }
  }

  .grid-container .row .vrtx-feed ul li::before {
    left: -20px;
    content: "\25CF";
    color: @color-bulletpoints;
  }

  .vrtx-isf-project-status {
    padding-bottom: 0;

    + h1 {
      padding-top: 15px;
    }
  }

  #main #vrtx-publications-wrapper.vrtx-frontpage-box {
    &,
    ul {
      padding: 0;
      margin: 0 !important;
    }

    li:first-child .vrtx-publication-title-compact {
      margin-top: 0;
    }
  }

  #vrtx-additional-content .vrtx-frontpage-box {
    border-bottom: none;
  }

  .not-for-ansatte #footer-wrapper {
    .col-1-2 .content,
    .col-1-2.adr .content,
    .col-2-5 .content {
      margin-top: 26px !important;
    }

    .footer-logo {
      position: static;
      display: block;
      display: block;
      width: 100%;
      float: none;
      height: 101px;

      img {
        margin-top: 0;
        padding-top: 0;
      }
    }

    .menu-label {
      font-size: 20px !important;
      font-size: 2rem !important;
      font-family: @serif !important;
      font-weight: 300 !important;
    }

    #footers {
      padding-bottom: 10px;

      [class*="col-"] {
        margin-bottom: 0;
      }

      .col-2-5 {
        width: 100%;

        .content {
          margin-left: 0;
        }
      }
    }
  }

  .footer-bottom-row p {
    width: auto;
  }
}
@media print {
  html {
    font-size: 60%;
  }

  html.msie {
    font-size: 81%;
  }
  // UiO print fix for new grid
  #vrtx-frontpage #main #vrtx-content:not(.vrtx-frontpage-wide) #vrtx-main-content:last-child {
    width: 100% !important;
  }

  .vrtx-frontpage-full-width.total-main #main,
  .vrtx-frontpage-full-width.total-main #main #total-main #vrtx-content,
  .vrtx-frontpage-full-width.total-main #main #total-main #vrtx-main-content {
    width: 100% !important;

    .row,
    h1 {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }

    h1 {
      margin-top: 30px;
    }
  }

  #main {
    margin-top: 30px !important;
  }

  #vrtx-frontpage #main h1 {
    margin-top: 0 !important;
  }

  #vrtx-main-content .grid-container.row-all-colored:first-child {
    margin-top: -30px;
  }

  .not-for-ansatte #head-wrapper #head #header {
    a#logo,
    span.header-intranett,
    span.header-samarbeid {
      position: static;
    }

    //a#logo {}

    span.header-intranett {
      margin-top: 20px;
    }

    span.header-intranett,
    span.header-samarbeid {
      display: block;
    }
  }

  .not-for-ansatte #head-wrapper {
    padding: 30px;
    background: @color-primary !important;
  }
  /*
  .vrtx-comp-person-list .isfTagParticipant .person-contact-info > span,
  .vrtx-comp-person-list .isfProjectParticipant .person-contact-info > span {
    &.vrtx-email a {
      &:after {
        content:" (" attr(href) ") ";
      }
    }
  }
*/
  .ui-tabs.ui-widget.ui-widget-content .ui-tabs-panel {
    padding: 0;
  }

  #vrtx-related-projects-completed-toggle,
  .all-messages,
  .footer-bottom-row,
  .more-participants,
  .ui-tabs-nav,
  .vrtx-more,
  .vrtx-more-external-publications,
  .vrtx-search-container,
  a.vrtx-publication-summary {
    display: none !important;
  }

  #vrtx-related-projects-completed,
  .ui-tabs-panel,
  .vrtx-comp-person-list .isfProjectParticipant,
  .vrtx-comp-person-list .isfTagParticipant,
  p.vrtx-publication-summary {
    display: block !important;
  }
}
// Graph colors

.d3-graph-line-chart {
  .line-chart-line {
    stroke: #373c82;
  }
}

body:not(.valg) .d3-graph-scatter-plot {
  .g-mean-circle {
    fill: #373c82;
  }

  .g-mean-line {
    stroke: #373c82;
  }

  .g-mean-text .number {
    fill: #373c82;
  }

  // svg circle {
  //   fill: #939494;
  // }

  svg .g-name.g-name-selected path {
    stroke: #0aa1cb;
  }

  .g-average-legend {
    color: #373c82;
  }

  .g-average-legend line {
    stroke: #373c82;
  }

  .g-average-legend circle {
    fill: #373c82;
  }

  .g-selected-legend {
    color: #0aa1cb;
  }

  .g-selected-legend line {
    stroke: #0aa1cb;
  }

  .g-selected-legend circle {
    fill: #0aa1cb;
  }

  .selected-name {
    fill: #0aa1cb;
    color: #0aa1cb;
  }

  svg .g-name circle {
    fill: #939494;
  }

  svg .g-name.g-name-selected circle {
    fill: #0aa1cb;
  }
}
